import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

class AgencyBanner extends Component {
    render(){
        let BannerData = this.props.BannerData;
        return(
            <section className="agency_banner_area bg_color" id="home">
                <div className="container custom_container">
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-center">
                            <div className="agency_content">
                            <Reveal effect="fadeInUp">
                                {
                                    BannerData.AgencyText.map(Agency =>{
                                        return(
                                            <React.Fragment key={Agency.id}>
                                                <h2 className="f_1000 t_color3 mb_40 wow fadeInLeft" data-wow-delay="0.3s">{Agency.btitle}</h2>
                                                <p className="f_600 t_color2 l_height28 wow fadeInLeft" data-wow-delay="0.4s">{Agency.description}</p>
                                                <p className="f_400 t_color2 l_height28 wow fadeInLeft" data-wow-delay="0.4s">{Agency.action}</p>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <div className="action_btn d-flex align-items-center mt_60">
                                <a  className="btn_hover agency_banner_btn wow fadeInLeft" data-wow-delay="0.5s"  href="javascript:void(Tawk_API.toggle())"> Get Started </a>

                                </div>
                                </Reveal>
                            </div>
                        </div>
                        <div className="col-lg-7 text-right">
                            <Reveal effect="fadeInRight"><img className="protype_img wow fadeInRight" data-wow-delay="0.3s" src={require('../../img/undraw_real_time_analytics_re_yliv.png')} alt=""/></Reveal>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default AgencyBanner;