import React from "react";
const Price = () => {
  return (
    <section className="event_price_area sec_pad" id="pricing">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>
            <span>14-day free trial.</span> No credit card required.
          </h2>
        </div>
        <div className="row align-items-center justify-content-center ">
          
          <div className="col-lg-3 col-sm-4">
            <div className="analytices_price_item event_price_item wow fadeInUp">
              <div className="p_head">
                <h5>Starter</h5>
                <div className="rate">$999<h6></h6></div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                Single MT4/MT5 Master account 
                </li>
                <li>
                  Unlimited Client accounts
                </li>

                {/*
                <li>
                No Recurring Fees <i className="ti-close"></i>
                </li> */}
              </ul>
              <div className="text-center">
                <a href="javascript:void(Tawk_API.toggle())" className="event_btn event_btn_two btn_hover">
                  Get now
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-4 price_card_gap">
            <div
              className="analytices_price_item event_price_item active wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="p_head">
                <h5>Professional</h5>
                <h6 className="tag">
                  <i className="icon_ribbon_alt"></i>Most popular
                </h6>
                <div className="rate">$1999<h6></h6></div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                  3 MT4/MT5 master account
                </li>
                <li>
                  Unlimited Client accounts
                </li>
                {/* <li>
                Support <i className="ti-check"></i>
                </li>
                <li>
                No Recurring Fees <i className="ti-close"></i>
                </li> */}
              </ul>
              <div className="text-center">
                <a href="javascript:void(Tawk_API.toggle())" className="event_btn btn_hover">
                  Get now
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-4">
            <div
              className="analytices_price_item event_price_item wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="p_head">
                <h5>ULTIMATE</h5>
                {/* <div className="rate">Inquiry for Prices<h6></h6></div> */}
                <div className="text-center">
                  <a href="javascript:void(Tawk_API.toggle())" className="event_btn event_btn_inq_Price btn_hover">
                    Inquiry for Prices
                  </a>
                </div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                3+ MT4/MT5 master accounts
                </li>
                <li>
                  Unlimited Client accounts
                </li>
                {/* <li>
                Support <i className="ti-check"></i>
                </li>
                <li>
                Hidden Fees <i className="ti-close"></i>
                </li> */}
              </ul>
              <div className="text-center">
                <a href="javascript:void(Tawk_API.toggle())" className="event_btn event_btn_two btn_hover">
                  Get now
                </a>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3 col-sm-4">
            <div
              className="analytices_price_item event_price_item wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="p_head">
                <h5>Freedom</h5>
                <div className="rate">$6999 + $250 for each<h6></h6></div>
              </div>
              <ul className="list-unstyled p_body">
              <li>
                5 or above MT4/MT5 master accounts
                </li>
                {/* <li>
                Support <i className="ti-check"></i>
                </li>
                <li>
                No Hidden Fees <i className="ti-close"></i>
                </li> */}
             {/* </ul>
              <div className="text-center">
                <a href="javascript:void(Tawk_API.toggle())" className="event_btn event_btn_two btn_hover">
                  Get now
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default Price;
