import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const Service = () => {
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad" className="app_testimonial_area" id="features">
                <div className="container">
                    <SeoTitle Title='Why you should try FxTxCopier ?' TitleP='Focus on your trades. Not on your clients'/>
                    <div className="row seo_service_info">
                        <Fade bottom duration={500}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon1.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Scalable</h4>
                                    </a>
                                    <p>Host 5000 clients in a single server, If you want more add more servers and host unlimited clients. Its less than cent for a client</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={700} >
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon2.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Low latency</h4>
                                    </a>
                                    <p>Our properitery engine copies any trade from any corner of the world in less than sub miliseconds to any MT4 accounts</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon5.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Risk management</h4>
                                    </a>
                                    <p>FxTxCopier support both client and server risk management. We have built in circuit breakers, Lot value adjusments and more</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div className="row seo_service_info">
                        <Fade bottom duration={500}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon1.png')} alt=""/>
                                    <a href=".#">
                                        <h4>100% automatic</h4>
                                    </a>
                                    <p>Once you setup FxTxCopier is fully automatic and requires no human interactions</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={700} >
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon2.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Cross broker copy</h4>
                                    </a>
                                    <p>Supports any broker and both MT4 and MT5 terminals. We support third party authentication.</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon5.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Customer support 24/7</h4>
                                    </a>
                                    <p>We have a amazing support team waiting to help you 24/7 throughout the year. Reach us anytime</p>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-lg-12 text-center mt_40">
                        <a className="btn_hover agency_banner_btn wow fadeInLeft" data-wow-delay="0.5s" href="javascript:void(Tawk_API.toggle())"> Get Started </a>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* <section className="seo_features_one sec_pad" id="extrafeatures">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content t_color2">
                                    <h2 className="t_color2">Third party authentication for sub account.</h2>
                                    <p>Faff about only a quid blower I don't want no agro bleeding chimney pot burke tosser cras nice one boot fanny.!</p>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/icon4.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon two"><img src={require('../../img/seo/icon3.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img_two.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Customer support 24/7</h2>
                                    <h6 className="wow fadeInUp">What a plonker bamboozled so I said say what a load of rubbish owt to do with me haggle.</h6>
                                    <p className="wow fadeInUp">Cheeky bugger gosh codswallop chap bamboozled blatant cracking goal brown bread, pear shaped cor blimey guvnor easy peasy lemon squeezy hotpot spiffing good time, chancer a spend a penny spiffing I don't want no agro tinkety tonk old fruit.</p>
                                    <a href="#reviews" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Reviews</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section> */}
        </React.Fragment>
    )
}

export default Service;
