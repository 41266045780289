import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home"
import NotFound from "./Pages/404";
import SignUp from "./Pages/SignUp";

import ScrollToTopRoute from "./ScrollToTopRoute";


class App extends Component {
  tawkToPropertyId = '6334028e54f06e12d8974712';
  tawkChatId = '1ge1ik0cc';

  componentDidMount() {
    this.loadTawk(this.tawkToPropertyId, this.tawkChatId);
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path="/404" component={NotFound} />
          <ScrollToTopRoute path="/SignUp" component={SignUp} />
        </Switch>
      </Router>
    );
  }

  loadTawk = (propertyID, chatID) => {
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); Tawk_API.embedded=`tawk_${propertyID}`;
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src=`https://embed.tawk.to/${propertyID}/${chatID}`;
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);})();
    }
}

export default App;
