const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description: "Don’t miss any updates of our new templates and extensions.!",
        }
    ],
    AboutWidget: [
        {
          id: 1,
          title: "Download",
          menuItems: [
              {
                id: 1,
                url: '#',
                text: 'Company',
              },
              {
                id: 2,
                url: '#',
                text: 'Android App',
              },
              {
                id: 3,
                url: '#',
                text: 'ios App',
              },
              {
                id: 4,
                url: '#',
                text: 'Desktop',
              },
              {
                id: 5,
                url: '#',
                text: 'Projects',
              },
              {
                  id: 6,
                  url: '#',
                  text: 'My tasks',
              },
          ],
      },
      {
            id: 2,
            title: "Help",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  text: 'FAQ',
                },
                {
                  id: 2,
                  url: '#',
                  text: 'Term & conditions',
                },
                {
                  id: 3,
                  url: '#',
                  text: 'Reporting',
                },
                {
                  id: 4,
                  url: '#',
                  text: 'Documentation',
                },
                {
                  id: 5,
                  url: '#',
                  text: 'Support Policy',
                },
                {
                    id: 6,
                    url: '#',
                    text: 'Privacy',
                  },
            ],
        },
    ],
    termsCondition: [
      {
          id: 1,
          title: "Team Solutions",
          menuItems: [
            {
              id: 1,
              url: '#',
              text: 'Support',
            },
            {
              id: 2,
              url: '#',
              text: 'Sales',
            },
            {
              id: 3,
              url: '#',
              text: 'Report Abuse',
            },
            {
              id: 4,
              url: '#',
              text: 'System Status',
            }
        ],
      }
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  icon: 'ti-facebook',
                },
                {
                  id: 2,
                  url: '#',
                  icon: 'ti-twitter-alt',
                },
                {
                  id: 3,
                  url: '#',
                  icon: 'ti-vimeo-alt',
                },
                {
                  id: 4,
                  url: '#',
                  icon: 'ti-pinterest',
                }
            ]
        }
    ],
    socialIcon:[
      {
        id: 1,
        url: 'https://www.facebook.com/',
        icon: 'ti-facebook',
      },
      {
        id: 2,
        url: '#',
        icon: 'ti-twitter-alt',
      },
      {
        id: 3,
        url: '#',
        icon: 'ti-vimeo-alt',
      },
      {
        id: 4,
        url: '#',
        icon: 'ti-pinterest',
      }
    ],
    copywrite:"© FxTxCopier Inc.. 2022 All rights reserved.",
    TestimonialText: [
      {
        id: 1,
        image: "new/review_1.png",
        description: "Customer service is very helpful, the quality of the FxTx Copier is fantastic. I especially love the trail stop and all the customization options. Already made my money back, 10/10 would recommend",
        authorName: "Victor Vrbancic",
        authorPost: "Forex Trader", 
      },
      {
        id: 2,
        image: "new/review_2.png",
        description: "FxTx Copier is 5++. I've been trying to find this program for a while and finally found it, plus it is super friendly and easy to setup. Plus I was able to try it free before paying  and instead of paying a high monthly fees I can pay a very fair Lifetime Membership. I've tried other similar Telegram copiers but this is definitely my #1. Customer service A++ best customer service I've had, very proactive, professional and prompt response.    I highly recommend trying it and after that you will be definitely be willing to buy it.",
        authorName: "Liliana Campa Moerbeeck",
        authorPost: "Investor", 
      },
      {
        id: 3,
        image: "new/review_3.png",
        description: "First, I'd like to send my thanks and compliments to the support team! Very patient and willing to do what's necessary to get things running.  Now for the product itself.... SOOOOOO GOOD. I have two other copy trade software that were made obsolete by FxTx Copier. I'll never miss another trade again.",
        authorName: "Aleks Sermuksnis",
        authorPost: "Part time trader", 
      },
      {
        id: 4,
        image: "new/review_4.png",
        description: "First off the customer service i have to say is the best customer service I have received. They have helped me day in and day out to resolve all the questions I had in regards to setting up the copier. I live in the US and I had trading at 3am and they were there to walk me through step by step on how to set up different trading configuration. They even created the configuration file and just sent it to me to attach to my channel. Honestly do not look anywhere else if you are interested in purchasing a telegram to mt4 copier because this is the place. Thank you FxTx Copier for all your help.",
        authorName: "Abisai Rivera",
        authorPost: "Trader", 
      }
    ],
    aboutLinks: [
      {
        id: 1,
        url: '#',
        item: 'Developer',
      },
      {
        id: 2,
        url: '#',
        item: 'Blog',
      },
      {
        id: 3,
        url: '#',
        item: 'Investor',
      },
      {
        id: 4,
        url: '#',
        item: 'Sitemap',
      },
      {
        id: 5,
        url: '#',
        item: 'Jobs',
      }
    ],
    helpLinks: [
      {
        id: 1,
        url: '#',
        item: 'Help and Contact',
      },
      {
        id: 2,
        url: '#',
        item: 'Fees',
      },
      {
        id: 3,
        url: '#',
        item: 'Security',
      },
      {
        id: 4,
        url: '#',
        item: 'App',
      },
      {
        id: 5,
        url: '#',
        item: 'Shop',
      }
    ],
    PrivacyLinks: [
      {
        id: 1,
        url: '#',
        item: 'Privacy Policy',
      },
      {
        id: 2,
        url: '#',
        item: 'Legal Agreement',
      },
      {
        id: 3,
        url: '#',
        item: 'Feedback',
      }
    ],
    about: [
      {
        id: 1,
        url: '#',
        text: 'Company',
      },
      {
        id: 2,
        url: '#',
        text: 'Leadership',
      },
      {
        id: 3,
        url: '#',
        text: 'Diversity',
      },
      {
        id: 4,
        url: '#',
        text: 'Jobs',
      },
      {
        id: 5,
        url: '#',
        text: 'Press',
      },
      {
        id: 6,
        url: '#',
        text: 'Wavelength',
      },
    ],
    Solution: [
      {
        id: 1,
        url: '#',
        text: 'Project Management',
      },
      {
        id: 2,
        url: '#',
        text: 'Agile',
      },
      {
        id: 3,
        url: '#',
        text: 'Task Management',
      },
      {
        id: 4,
        url: '#',
        text: 'Reporting',
      },
      {
        id: 5,
        url: '#',
        text: 'Work Tracking',
      },
      {
        id: 6,
        url: '#',
        text: 'See All Uses',
      },
    ],
    teamSolution: [
      {
        id: 1,
        url: '#',
        text: 'Engineering',
      },
      {
        id: 2,
        url: '#',
        text: 'Designers',
      },
      {
        id: 3,
        url: '#',
        text: 'Sales',
      },
      {
        id: 4,
        url: '#',
        text: 'Developers',
      },
      {
        id: 5,
        url: '#',
        text: 'Marketing',
      },
      {
        id: 6,
        url: '#',
        text: 'See All team',
      },
    ],
};
export default FooterData;