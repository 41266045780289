import React from 'react';
import AgencyBanner from '../components/Banner/AgencyBanner';
import BannerData from '../components/Banner/BannerData';
import CustomNavbar from '../components/CustomNavbar';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import Service from '../components/Service/Service';
import Footer from '../components/Footer/Footer';
import Testimonial from '../components/Testimonial/Testimonial';
import FooterData from '../components/Footer/FooterData';
import Price from "../components/Price";
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Contacts from '../components/Contacts';


export const Home = () => (
    <div className="body_wrapper">
        <CustomNavbar mClass="menu_tracking" nClass="mr-auto" hbtnClass="tracking_btn"/>
        <AgencyBanner BannerData={BannerData}/>
        <AppFeaturesTwo/>
        <Service/>
        <Testimonial tClass="testimonial_area_four sec_pad" FooterData={FooterData}/>
        <Price />
        <Contacts/>
        {/* <PrototypeService/> */}
        {/* <Footer FooterData={FooterData}/> */}
    </div>
)